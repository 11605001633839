import React, { useEffect, useState, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Modal, Dropdown } from "react-bootstrap";
// img
import all from "../assets/images/all.svg";
import toys from "../assets/images/toys.svg";
import home from "../assets/images/home.svg";
import tools from "../assets/images/tools.svg";

import outdoor from "../assets/images/outdoor.svg";
import sports from "../assets/images/sports.svg";
import pets from "../assets/images/pets.svg";
import electronics from "../assets/images/electronics.svg";
import cloth from "../assets/images/cloth.svg";
import others from "../assets/images/others.svg";
import search_icon from "../assets/images/search_icon.svg";
import filter from "../assets/images/filter.svg";
import ship from "../assets/images/ship.svg";
import plus1 from "../assets/images/plus1.svg";

import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link } from "react-router-dom";
import { getProductCat, getMyProList } from "../action/user.action";
import MyProductList from "../components/myproduct/MyProductList";
import { useDispatch, useSelector } from "react-redux";
import config from "../config/index";

import ScrollToTop from "react-scroll-to-top";
import { ReactComponent as MySVG } from "../assets/images/uparrow.svg";


export default function Myproduct() {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue1, setSelectedValue1] = useState(null);
  const [catagory, setcatagory] = useState([]);
  const [myproductlist, setmyproductlist] = useState([]);
  const [activecat, setactivecat] = useState(0);
  const [search, setsearch] = useState("");
  const [show, setshow] = useState(false);
  const [listType, setlistType] = useState("grid");

  const [sort, setsort] = useState(null);

  // const [myproduct, setmyproduct] = useState([]);

  // const myproduct = useSelector((state) => state?.myproduct.completed);

  const userstore = useSelector((state) => state.userstore);


  const handleSelect1 = (selectedItem) => {
    console.log(selectedItem, "selectedItem");
    setSelectedValue1(selectedItem);
    if (selectedItem == "Sell Cost :Low - High") {
      setsort(JSON.stringify({ sell_cost: 1 }))
    } else if (selectedItem == "Sell Cost :High - Low") {
      setsort(JSON.stringify({ sell_cost: -1 }))
    }
    else if (selectedItem == "Recent") {
      setsort(JSON.stringify({ createdAt: -1 }))
    } else if (selectedItem == "Oldest") {
      setsort(JSON.stringify({ createdAt: 1 }))
    }
    console.log(sort, "sort");
  };



  // console.log(myproduct, "myproduct");

  const handleSelect = (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));

    // setSelectedValue(selectedItem);
  };


  const trackStyles = {
    backgroundColor: "#000 !important", // specify your desired track color
    borderRadius: "5px" // optional: add border radius for a rounded look
  };

  useEffect(() => {
    getCatagory();
    // getMyProductData();
  }, []);

  useEffect(() => {
    getMyProductData();
  }, [activecat, search, sort, selectedValue1, selectedValue]);

  const getMyProductData = async () => {
    try {

      if (sort == null) {
        setsort(JSON.stringify({ createdAt: -1 }))
      }
      if (selectedValue1 == "All") {
        setsort(JSON.stringify({ createdAt: -1 }))
      }

      console.log(sort, "sortsort");

      let { status, result } = await getMyProList(sort)

      console.log(status, result, "status");
      // setmyproduct(result?.completed)

      getProduct(result?.completed);


    } catch (err) {
      console.log(err);
    }
  }


  const getCatagory = async () => {
    try {
      const { result, status } = await getProductCat();
      if (result) {
        setcatagory(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProduct = async (myproduct) => {
    try {
      var id = 0;
      if (activecat != 0) {
        id = activecat?._id;
      }

      var filteredData;

      if (selectedValue != undefined && selectedValue._id != 0) {
        if (id != 0) {
          filteredData = myproduct.filter(
            ({ product, userstore }) =>
              product?.categorie_id == id &&
              userstore._id == selectedValue._id &&
              product.product_title.toLowerCase().includes(search.toLowerCase())
          );
        } else {
          filteredData = myproduct.filter(
            ({ product, userstore }) =>
              userstore?._id == selectedValue?._id &&
              product?.product_title
                ?.toLowerCase()
                .includes(search.toLowerCase())
          );
        }
      } else {
        if (id != 0) {
          filteredData = myproduct.filter(
            ({ product, userstore }) =>
              product?.categorie_id == id &&
              product.product_title.toLowerCase().includes(search.toLowerCase())
          );
        } else {
          filteredData = myproduct.filter(({ product, userstore }) =>
            product?.product_title?.toLowerCase().includes(search.toLowerCase())
          );
        }
      }
      setmyproductlist(filteredData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    storeSearch();
  }, [selectedValue]);

  const storeSearch = () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  function showList(val) {
    setlistType(val)
  }

  return (
    <div className="page_wrapper">
      <Sidebar navLink={"/myproduct"} />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <h2 className="h3tag bdr mb-3">My Inventory ({myproductlist?.length != 0 ? myproductlist?.length : 0})</h2>

          {/* <Scrollbars
            style={{ width: "100%", height: 135 }}
            trackXProps={{ style: trackStyles }}
          > */}
          <div className="sel_flx sel_flx_chg">
            <div
              onClick={() => setactivecat(0)}
              className={activecat == 0 ? "active" : ""}
            >
              <img src={all} className="img-fluid" alt="logo" />
              <span>All Categories</span>
            </div>

            {catagory &&
              catagory.map((item, index) => (
                <div
                  onClick={() => {
                    setactivecat(item);
                  }}
                  className={activecat?._id == item?._id ? "active" : ""}
                >
                  <img
                    src={`${config.API_URL}/catagory/${item.logo}`}
                    className="img-fluid"
                    alt="logo"
                  />
                  <span>{item?.CategoryName}</span>
                </div>
              ))}
          </div>
          {/* </Scrollbars> */}
          <div className="categories">
            <div className="hflx mt-3">
              <h6>
                {" "}
                {activecat == 0 ? "All Categories" : activecat?.CategoryName}
              </h6>{" "}
              <div className="flx">

                <div className="drp_dwn" >

                  <Dropdown onSelect={handleSelect1}>
                    <Dropdown.Toggle className="bg-transparent" >
                      <img src={filter} className="img-fluid" />
                      <span className="mx-2" > {selectedValue1 ? ` ${selectedValue1}` : 'Sort by'}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100" >
                      <Dropdown.Item eventKey="All">All</Dropdown.Item>
                      <Dropdown.Item eventKey="Sell Cost :High - Low">Sell Cost :High - Low</Dropdown.Item>
                      <Dropdown.Item eventKey="Sell Cost :Low - High">Sell Cost :Low - High</Dropdown.Item>

                      <Dropdown.Item eventKey="Recent">Recent</Dropdown.Item>
                      <Dropdown.Item eventKey="Oldest">Oldest</Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>
                </div>


                <div className="drp_dwn drp_dwn_hgt">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle className="bg-transparent">
                      {/* <img src={filter} className="img-fluid" /> */}
                      <span className="mx-2">
                        {" "}
                        {selectedValue
                          ? ` ${selectedValue?.store_name?.charAt(0).toUpperCase() + selectedValue?.store_name?.slice(1)}-${selectedValue?.default_storeid?.Store_name}`
                          : "Select Store"}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          _id: 0,
                          store_name: "All",
                          default_storeid: { Store_name: "" }
                        })}
                      >
                        All
                      </Dropdown.Item>
                      {userstore &&
                        userstore.map((item) => (
                          <Dropdown.Item eventKey={JSON.stringify(item)}>
                            {item?.store_name?.charAt(0).toUpperCase() + item?.store_name?.slice(1)} -
                            {item?.default_storeid?.Store_name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="input_box">
                  <div class="input-group">
                    <span
                      class="input-group-text cursor-pointer bg-transparent"
                      id="basic-addon2"
                    >
                      <img src={search_icon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control bg-transparent border-end-0"
                      name="text"
                      placeholder="Type something "
                      onChange={(e) => setsearch(e.target.value)}
                    />


                    {/* <span class="input-group-text bg-transparent"><button className="primary_btn sm_txt py-1">Search</button></span> */}
                  </div>
                </div>
                <div class="btn-group listGridButton">
                  <a href="#" className={listType == "grid" ? "btn btn-primary active" : "btn btn-primary"} onClick={() => showList("grid")}><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-12.5 15.5h-4v-4h4zm1.5-4h4v4h-4zm9.5 0v4h-4v-4zm-15-5.5h4v4h-4zm5.5 0h4v4h-4zm5.5 0h4v4h-4zm-11-5.5h4v4h-4zm5.5 0h4v4h-4zm5.5 0h4v4h-4z" fill-rule="nonzero" /></svg> Gallery</a>
                  <a href="#" className={listType == "list" ? "btn btn-primary active" : "btn btn-primary"} onClick={() => showList("list")}><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m10.5 17.25c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75zm-1.5-3.55c0-.53-.47-1-1-1h-5c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm-5.5.5h4v3.3h-4zm7-2.2c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75zm-1.5-6c0-.53-.47-1-1-1h-5c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm-5.5.5h4v3.3h-4zm7 .25c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75z" fill-rule="nonzero" /></svg> List</a>
                </div>

              </div>
            </div>

            <MyProductList myproduct={myproductlist} listType={listType} />

            <div className="">
              <ScrollToTop className="Scrol_Box_div" smooth component={<MySVG />} />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
