
import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown, Form } from 'react-bootstrap';
import search_icon from "../../assets/images/search_icon.svg";
import tag1 from "../../assets/images/tag1.svg";
import box from "../../assets/images/box.svg";
import dot from "../../assets/images/dot.svg";
import eye from "../../assets/images/eye.svg";
import setting from "../../assets/images/setting.svg";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/index"
import { renameStore, getSiteSetting } from "../../action/user.action"
import { getUserStore } from "../../redux/features/userstore/userStoreSlice";
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice"
import { Country, State, City } from "country-state-city";
import { toastAlert } from "../../utils/toast";



function DisplayStorePop(props) {
  const dispatch = useDispatch();

  let { show, handleClose, handleShow1 } = props
  const defaultStore = useSelector((state) => state.userstore);
  const myproduct = useSelector((state) => state.myproduct);

  const [search, setsetsearch] = useState("")
  const [storedata, setstoredata] = useState([])
  const [show3, setshow3] = useState(false)
  const [rename, setrenamedata] = useState("")
  const [renameid, setrenameid] = useState("")
  const [storelogo, setstorelogo] = useState("")
  const [storecountry, setstorecountry] = useState("")
  const [storecode, setstorecode] = useState("")

  const [selectedValue, setSelectedValue] = useState(null);

  const [validateError, setValidateError] = useState({});
  const [groupcount, setgroupcount] = useState();
  const [file, setfile] = useState("")
  const [preview, setpreview] = useState("")

  const [searchQuery, setSearchQuery] = useState("");
  const countries = Country.getAllCountries();

  const [orerCount, setOrderCount] = useState()


  useEffect(() => {
    getStoreData()
    getProductCount()
  }, [search, handleClose])



  const handleSelect = (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // const orderCount = async () => {
  //   try {

  //     defaultStore.

  //     setOrderCount()

  //   } catch (error) {

  //   }
  // }

  const getProductCount = () => {
    try {
      const groupedIds = {};

      const orderCount = {}

      // Iterate over the cat array
      defaultStore.forEach(catObj => {

        console.log(myproduct, 'myproductmyproductmyproduct');
        const matchedProduct = myproduct?.completed?.filter(prodObj => prodObj?.store_id == catObj?._id);
        console.log(matchedProduct, 'matchedProductmatchedProduct  1111   ');
        if (matchedProduct) {
          if (!groupedIds[catObj?._id]) {
            groupedIds[catObj?._id] = [];
          }
          groupedIds[catObj?._id].push(matchedProduct);
        }
      });

      console.log(groupedIds, 'groupedIds');

      setgroupcount(groupedIds)
    } catch (err) {
      console.log(err);
    }
  }


  const getStoreData = () => {
    try {

      if (search != "") {
        const filteredData = defaultStore.filter((product) =>
          product.store_name.toLowerCase().includes(search.toLowerCase()));
        setstoredata(filteredData)
      } else {
        setstoredata(defaultStore)
      }
    } catch (err) {
      console.log(err);
    }
  }

  console.log(storedata, 'defaultStoredefaultStoredefaultStoredefaultStore');

  const handleRename = (data) => {

    setshow3(true)
    setrenamedata(data.store_name)
    setrenameid(data._id)
    setstorelogo(data.store_logo)
    setstorecountry(data.store_location)
    setstorecode(data.country_code)
  }

  const handleclose3 = (data) => {
    setshow3(false)
    setrenamedata([])
    setfile("")
    setpreview("")
  }

  const handleChange = (e) => {
    try {
      var { value } = e.target
      setrenamedata(value)
    } catch (err) {
      console.log(err);

    }
  }


  const Fromvalidation = async () => {
    try {
      var validateError = {};

      if (rename.trim() == "") {
        validateError.name = "Store Name Required";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };


  const handleRenameSubmit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {

        let Formdata = new FormData();
        Formdata.append("Rename", rename);
        Formdata.append("id", renameid);
        if (file != "") {
          Formdata.append("image", file);
        } else {
          Formdata.append("image", storelogo);
        }
        if (selectedValue != null) {
          Formdata.append("code", selectedValue.isoCode);
          Formdata.append("location", selectedValue.name);
        } else {
          Formdata.append("code", storecode);
          Formdata.append("location", storecountry);
        }

        var { status, message } = await renameStore(Formdata)
        if (status) {
          dispatch(getUserStore());
          dispatch(getMyproduct());
          setshow3(false)
          setfile("")
          setpreview("")
          // handleClose()
          toastAlert("success", "Update Successfully");

        }

      }
    } catch (err) {

    }
  }



  const handlefilechanges = ({ target: { files } }) => {
    if (!files.length) {
      return;
    }

    let picture = files[0];
    if (!picture.type.toLowerCase().startsWith("image/")) {
      throw Error("Invalid Image Format");
    }
    let pre = URL.createObjectURL(picture)
    setpreview(pre)
    setfile(picture)

  };

  console.log(orerCount, 'orderccccccccccccccccccc');

  return (
    <>
      <Modal show={show} size="lg" centered className="custom_modal" onHide={handleClose}>
        <Modal.Header className="border-0" closeButton  >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input_box">
            <div class="input-group mb-3">
              <span class="input-group-text cursor-pointer" id="basic-addon2">
                <img src={search_icon} className="img-fluid" />
              </span>
              <input
                onChange={(e) => setsetsearch(e.target.value)}
                type="text"
                className="form-control"
                name="text"
                placeholder="Search Store Name" />
            </div>
          </div>
          <div className="modal_box" >
            <div className="row" >
              {storedata.length > 0 && storedata?.map((item, index) => (

                <div className="col-lg-6" >

                  {console.log(item, 'iiiiiiiiiiiiii')}
                  <div className="box_bdr">
                    <div className="d-flex align-items-center gap-2" >
                      <img
                        style={{ width: "30px" }}
                        src={`${config.API_URL}/store/${item?.default_storeid?.logo}`}
                        class="img-fluid" alt="img" />


                      <div className="main_name" >{item?.default_storeid?.Store_name}(1)</div>
                    </div>
                    <div className="main_box d-block" >
                      <div className="d-flex align-items-center mb-3">
                        {item?.store_logo == "" ?
                          <div className="round">{item?.store_name[0].toUpperCase()}</div> :
                          <div className="round">
                            <img
                              style={{ width: "40px" }}
                              src={`${config.API_URL}/userstore/${item?.store_logo}`}
                              class="img-fluid" alt="img" />
                          </div>

                        }
                        <div className="d-flex align-items-center justify-content-between w-100" >
                          <div>
                            <div className="d-flex" >
                              <p>{item?.store_name?.charAt(0).toUpperCase() + item?.store_name?.slice(1)}</p>
                              <img src={tag1} className="img-fluid ms-3 me-1" />
                              <span className="box" >{item?.country_code}</span>
                            </div>
                            <div>
                              <img src={eye} style={{ width: "13px" }} className="img-fluid me-1" />
                              <span>{item?.view}</span>
                              &nbsp; &nbsp; &nbsp;

                              <img src={box} className="img-fluid me-1" />
                              <span>{item?.result[0]?.noOfpro ? item?.result[0]?.noOfpro : 0}</span>
                              {/* <span>{groupcount[item?._id][0]?.length}</span> */}
                              &nbsp; &nbsp; &nbsp;

                              {item?.status == "active" ?
                                <span className="badge bg-success rounded-pill">
                                  {item?.status}</span> : <span className="badge bg-danger rounded-pill">
                                  {item?.status}</span>}

                            </div>


                          </div>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle className="bg-transparent p-0 m-0 border-0" id="dropdown-basic">
                                <img src={dot} className="img-fluid me-3" />

                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleRename(item)}>
                                  <img src={setting} className="img-fluid me-3" />

                                  Settings</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center gap-2 pb-3" >
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" stroke="#CEF3FB" stroke-opacity="0.8" />
                  <path d="M8.525 14.8965C6.60257 14.8965 5 15.657 5 17.9515C5 19.7662 6.07708 20.9118 8.59028 20.9118C11.5278 20.9118 11.7171 19.1069 11.7171 19.1069H10.281C10.281 19.1069 9.97417 20.0861 8.4891 20.0861C7.28472 20.0795 6.41 19.3158 6.41 18.2452H11.8542V17.5925C11.8542 16.5252 11.1361 14.8965 8.525 14.8965ZM8.47604 15.7516C9.62819 15.7516 10.4148 16.4044 10.4148 17.3836H6.43937C6.43937 16.3359 7.46424 15.7516 8.47604 15.7516Z" fill="#E53238" />
                  <path d="M11.5567 13V20.0598C11.5567 20.4612 11.5273 21.039 11.5273 21.039H12.8949C12.9245 20.7821 12.9409 20.5239 12.9439 20.2654C12.9439 20.2654 13.6195 21.2446 15.4571 21.2446C17.3926 21.2446 18.721 20.0043 18.721 18.2287C18.721 16.574 17.5133 15.2456 15.4571 15.2456C13.5477 15.2456 12.9537 16.1986 12.9537 16.1986V13H11.5567ZM15.1176 16.104C15.4009 16.0868 15.6847 16.1293 15.9506 16.2287C16.2164 16.328 16.4585 16.482 16.6612 16.6806C16.8639 16.8793 17.0227 17.1183 17.1273 17.3822C17.2319 17.646 17.28 17.9288 17.2685 18.2124C17.2772 18.4968 17.2274 18.7799 17.1222 19.0442C17.0171 19.3086 16.8588 19.5485 16.6572 19.7492C16.4556 19.9499 16.2149 20.1071 15.9501 20.2111C15.6853 20.315 15.4019 20.3635 15.1176 20.3535C14.8324 20.3688 14.5471 20.3243 14.28 20.2229C14.0129 20.1216 13.77 19.9656 13.5666 19.765C13.3632 19.5645 13.204 19.3236 13.099 19.058C12.994 18.7923 12.9456 18.5076 12.9569 18.2222C12.945 17.9372 12.993 17.6529 13.0979 17.3876C13.2028 17.1223 13.3623 16.8821 13.566 16.6824C13.7697 16.4827 14.0131 16.328 14.2804 16.2284C14.5477 16.1287 14.8329 16.0863 15.1176 16.104Z" fill="#0064D2" />
                  <path d="M21.7185 15.2128C18.9801 15.2128 18.8071 16.6815 18.8071 16.9165H20.1682C20.1682 16.9165 20.24 16.0581 21.6239 16.0581C22.5247 16.0581 23.2232 16.4628 23.2232 17.2364V17.5138H21.6239C19.5023 17.5138 18.3828 18.1209 18.3828 19.3546C18.3828 20.5884 19.4175 21.2281 20.8177 21.2281C22.727 21.2281 23.3407 20.1935 23.3407 20.1935C23.3407 20.6047 23.3733 21.0094 23.3733 21.0094H24.5842C24.5842 21.0094 24.5385 20.5068 24.5385 20.1869V17.4159C24.5385 15.5946 23.0404 15.2128 21.7185 15.2128ZM23.2232 18.3428V18.7084C23.2232 19.1914 22.9196 20.3828 21.1375 20.3828C20.1584 20.3828 19.7439 19.9062 19.7439 19.3546C19.7439 18.3494 21.1539 18.3428 23.2232 18.3428Z" fill="#F5AF02" />
                  <path d="M23.9307 15.2128H25.5039L27.7625 19.6582L30.0146 15.2128H31.4376L27.3349 23.1408H25.84L27.0248 20.9376L23.9307 15.2128Z" fill="#86B817" />
                </svg>
                <div className="main_name" >ebay(1)</div>
              </div>  */}
                    </div>
                  </div>
                </div>
              ))
              }
            </div>
            <br></br>


            <div className="add">
              <button className="primary_btn" onClick={handleShow1}> + Add Store</button>
            </div>
          </div>
        </Modal.Body>
      </Modal >

      <Modal show={show3} centered size="sm" className="custom_modal" onHide={handleclose3} >
        <Modal.Header className="mb-3" closeButton>
          <div className="cursor-pointer" >
            <h6>Edit</h6>
          </div>
        </Modal.Header>


        <Modal.Body>
          <Form.Label className="bg-transparent">
            <span>
              Store Name
            </span>
          </Form.Label>
          <input
            type="text"
            className="form-control"
            name="text"
            value={rename}
            placeholder="Search anything"
            onChange={handleChange}
          />
          {validateError.name && (
            <span className="text-danger">
              {validateError.name}
            </span>
          )}
          <br></br>
          <label className="sem sm_txt mb-3">Store Location</label>
          <div className="drp_dwn drp_dwn_hgt mb-4">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle className="d-flex justify-content-between">
                {selectedValue ? ` ${selectedValue?.name}` : storecountry}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                <input
                  type="text"
                  style={{ width: "90%" }}
                  className="form-control mb-2 mx-3"
                  placeholder="Search for a country..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                {
                  filteredCountries.map((item) => (
                    <Dropdown.Item eventKey={JSON.stringify(item)}>
                      {item?.name}
                    </Dropdown.Item>
                  ))
                  // <Dropdown.Item eventKey="United States">United States</Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
            {validateError.country && (
              <span className="text-danger">{validateError.country}</span>
            )}
          </div>
          {/* <br></br> */}



          <div>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className="bg-transparent">
                <span>
                  Store Logo
                </span>
              </Form.Label>
              <Form.Control type="file" onChange={handlefilechanges} accept=".png,.jpeg,.jpg" />
            </Form.Group>
            <p style={{ color: "blue" }}>Acceptable format: png,jpeg,jpg</p>
          </div>
          {/* preview */}



          <div className="round">
            {storelogo == "" || preview != "" ?
              <>
                {preview != "" ?
                  <img
                    style={{ width: "40px" }}
                    src={preview}
                    class="img-fluid" alt="img" /> : ""}
              </>
              :
              <img
                style={{ width: "40px" }}
                src={`${config.API_URL}/userstore/${storelogo}`}
                class="img-fluid" alt="img" />
            }
          </div>


          &nbsp; &nbsp;

        </Modal.Body>
        <button class="primary_btn" onClick={handleRenameSubmit}> Confirm
        </button>
      </Modal >
    </>


  )
}

export default DisplayStorePop