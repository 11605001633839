import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { getMapchart } from "../../action/user.action"
import { regional } from "../../components/globlemap/features"

export const data = [
    ["Country", "Orders"
    // , { role: 'tooltip', type: 'string', p: { html: true } }
],
];


export default function MapChart(props) {



    let { selectedValue } = props
    console.log(selectedValue, 'selectedValue');



    useEffect(() => {
        getdata()
    }, [selectedValue])

    const getdata = async () => {

        let store = selectedValue?._id || "all"

        let { status, result } = await getMapchart({ store })
        if (status) {
            console.log(result, "fdd");
            for (let i = 0; i < result.length; i++) {
                data.push([result[i]?.country_code,
                    //  result[i]?.shopDescr[0]?.total,

                `Order: ${result[i]?.shopDescr[0]?.total||0},Store : ${result[i]?.store_name}`
                ])
            }
        }
    }

    const options = {
        region: regional[selectedValue == "all" ? "all" : selectedValue.country_code],
        colorAxis: { colors: ["#51ca92","#7bca51", "#544cdb", "#f11a11","#00153f","#02609f"] },
        backgroundColor: "#f5f5f5",
        datalessRegionColor: "#137ed4",
        defaultColor: "#f1df20",
        displayMode: 'auto',

        zoomLevel: 10,
    };


    console.log(data, "data");


    return (
        <Chart
            chartEvents={[
                {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                        console.log("Selected : " + region);
                    },
                },
            ]}
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={data}

            options={options}


        />
    );
}


// import React, { useEffect } from 'react';
// import { Chart } from 'react-google-charts';

// const GeoChart = () => {
//   useEffect(() => {
//     const loadGoogleCharts = async () => {
//       await new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = 'https://www.gstatic.com/charts/loader.js';
//         script.onload = resolve;
//         script.onerror = reject;
//         document.head.appendChild(script);
//       });

//       // Load Google Charts library
//       window.google.charts.load('current', {
//         packages: ['geochart'],
//       });
//       window.google.charts.setOnLoadCallback(drawRegionsMap);
//     };

//     loadGoogleCharts();

//     function drawRegionsMap() {
//       const data = window.google.visualization.arrayToDataTable([
//         ['Country', 'Tooltip1', 'Tooltip2', 'Tooltip3'],
//         ['Germany', 'Tooltip1 content', 'Tooltip2 content', 'Tooltip3 content'],
//         ['United States', 'Tooltip1 content', 'Tooltip2 content', 'Tooltip3 content'],
//         ['Brazil', 'Tooltip1 content', 'Tooltip2 content', 'Tooltip3 content'],
//         ['Canada', 'Tooltip1 content', 'Tooltip2 content', 'Tooltip3 content'],
//         ['France', 'Tooltip1 content', 'Tooltip2 content', 'Tooltip3 content'],
//         ['RU', 'Tooltip1 content', 'Tooltip2 content', 'Tooltip3 content']
//       ]);

//       const options = {
//         tooltip: {
//           isHtml: true,
//         }
//       };

//       const chart = new window.google.visualization.GeoChart(document.getElementById('regions_div'));

//       chart.draw(data, options);
//     }
//   }, []);

//   return (
//     <div id="regions_div" style={{ width: '900px', height: '500px' }}></div>
//   );
// };

// export default GeoChart;
