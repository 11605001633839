export function ValidateUserName(val) {
    if (/^(?=.*[a-zA-Z])[a-zA-Z\s]+$/.test(val)) {
        return true;
    }
    return false;
}
export function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
    }
    return false;
}
export const isEmpty = (value) =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);

export const validPositive = (e) => {
    if (
        new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
        (e.target.value = "")
    ) {

    }

};

export const validSwift = (e) => {
    if (/[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/i.test(e.key)) {
        console.log(true,"validSwift");
        return true;
    }
    console.log(false,"validSwift");

    return false;
  };


export const validSpecialPositive = (e) => {
    if (/[*|\":<>[\]{}`\\()';@&$!#%^]/.test(e.key)) {
        return true;
    }
    return false;
  };