import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_violet.svg";
export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="row">
            <div className="col-lg-3">
              <Link to="/" >
              <img src={logo} class="img-fluid mt-auto mb-4" alt="img" />
              </Link>
              <h5>ShoppyAI, we are not selling,  </h5>
              <h5>We are succeeding!  </h5>

              {/* <h6 className="mb-4" >We are succeeding!</h6> */}
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>Shoppy AI</h5>
                <Link to="/" >About</Link>
                <Link to="/" >Investors</Link>
                <Link to="/" >Partners</Link>
                <Link to="/" >Affiliates</Link>
                <Link to="/" >Legal</Link>
                <Link to="/" >Service status</Link>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>Support</h5>
                <Link to="/" >Merchant support</Link>
                <Link to="/" >Help center</Link>
                <Link to="/" >Hire a Partner</Link>
                <Link to="/" >Community</Link>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right_box">
                <h5>Products</h5>
                <Link to="/" >Shop</Link>
                <Link to="/" >Shop Pay</Link>
                <Link to="/" >ShoppyAI Plus</Link>
                <Link to="/" >Linkpop</Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="right_box">
                <h5>Developers</h5>
                <Link to="/" >ShoppyAI.dev</Link>
                <Link to="/" >API Documentation</Link>
                <Link to="/" >Dev Degree</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blw">
          <div>
          <ul className="link" >
            <li>
              <a href="#">Terms of service </a>{" "}
            </li>
            <li>
              <a href="#"> Privacy Policy</a>{" "}
            </li>
            <li>
              <a href="#">Sitemap </a>{" "}
            </li>
            <li>
              <a href="#"> Privacy Choices</a>{" "}
            </li>
          </ul>
          <p>
            Copy right © {new Date().getFullYear()} <a href="#" className="atag" >ShoppyAI</a>, All
            rights Reserved
          </p>
          </div>
          <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
            </ul>
        </div>
      </div>
    </footer>
  );
}
