import React, { useEffect, useState } from "react";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";
import { getAdsPack, AdsplanSubscribtion } from "../action/user.action.js"

import { Modal, Dropdown, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { toastAlert } from "../utils/toast.js";


export default function Advertisementpackage() {
  const dispatch = useDispatch();

  const [packdata, setpackdata] = useState([])
  const [show3, setshow3] = useState(false)
  const [show, setshow] = useState(100000)
  const [selectpack, setselectpack] = useState([])

  const user = useSelector((state) => state.account);



  useEffect(() => {
    getpackage()
  }, [])


  const getpackage = async () => {
    try {
      var { status, result } = await getAdsPack()

      console.log(result, 'result');
      if (result) {
        let arr1 = []

        // await result.map(val => {
        //   Object.keys(val.packList[0]).forEach(key => {
        //     console.log('key', key, val.packList[0][key]);
        //     arr1.push({ [key]: val.packList[0][key] })
        //   });

        //   console.log(arr1, 'packdatapackdatapackdata');
        //   val.pack = arr1
        //   arr1 = []
        // })
        setpackdata(result)

      }
    } catch (err) {
      console.log(err);

    }
  }

  console.log(packdata, 'packdatapackdata');

  var color = ["", "rose", "green", "blue"]
  var colors = ["yellow", "green", "black", "grey", "lightgrey", "violet"]

  const handleOpen = (item) => {
    setshow3(true)
    setselectpack(item)
  }




  const handleBuyPackage = async () => {
    try {

      let reqdata = {
        planid: selectpack._id
      }
      let { status, message } = await AdsplanSubscribtion(reqdata)



      if (status) {
        setshow3(false)
        dispatch(getUserProfile());
        toastAlert("success", message)
      } else {
        toastAlert("error", message)
      }

    } catch (err) {
      console.log(err);
    }

  }

  const handleclick = (index) => {
    setshow(index);
    if (show == index) {
      setshow(10000);
    }
  }



  return (
    <div className="page_wrapper" >
      {/* <Sidebar navLink={"/advertisement-package"} /> */}
      <div  >
        {/* <Navbarinner /> */}
        <div >
          <center>
            <h2 className="h3tag bdr mb-5" >Advertisement Package</h2>
          </center >
          <div className="plan_box">
            {/* <h3 className="text-center" >Pricing Plans for Everyone</h3>
            <p className="text-center px-lg-5 mx-lg-5">Maecenas sem massa, porttitor vestibulum magna et, porta auctor ex. Donec bibendum turpis non consectetur lobortis. Donec nisl dolor, ornare ac risus vitae, consequat accumsan ex. Phasellus ultricies dolor non ullamcorper elementum. Duis ultricies ex sed mi blandit sagittis. Sed mollis sollicitudin magna, ut varius urna consequat vitae.</p> */}

            <div className="grid">
              {packdata && packdata.map((item, index) => (


                <div className={`box`} >
                  <div className={`abv_box ${item?.color}`}>
                  <p> <strong style={{ fontSize: 'x-large' }}>{item?.package_name}</strong></p>
                    <h2>${item?.package_amount}</h2>

                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" onClick={() => handleclick(index)}>
                        <span>Billed Monthly</span>
                      </Dropdown.Toggle>


                    </Dropdown>
                    {user.plan_ads_type != "none" && user.plan_ads_id == item._id ?

                      <button className="primary_btn">Subscribed</button> :

                      <button className="primary_btn" onClick={() => { handleOpen(item) }}>Buy Now</button>

                    }
                  </div>
                  <div className={`blw_box ${show == index ? "show" : "hide"}`} >

                    <ul>

                      <li>Advertise to {item?.Advertise} clients Worldwide</li>

                      {
                        item?.packList?.length > 0 && item?.packList?.map((val, i) => (
                          <>
                           
                            <li  >{val.list}
                            </li>
                          </>
                        ))
                      }
                      {/* <li className={item?.Facebook ? "" : "text_line"} >Facebook ADs
                      </li>
                      <li className={item?.Google ? "" : "text_line"} >Google Ads + YouTube
                      </li>
                      <li className={item?.Taboola ? "" : "text_line"} >Taboola ADs
                      </li> */}
                    </ul>

                  </div>
                </div>))}



            </div>
          </div>
        </div>
      </div>

      <Modal show={show3} centered size="sm" className="custom_modal" >
        <Modal.Header className="mb-3"  >
          <div className="cursor-pointer" >
            <h6>Are You sure to Buy <strong>{selectpack.package_name}</strong> Package With
              <strong> {selectpack.package_amount}</strong> USD?</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button class="secondary_btn" style={{ backgound: "none !important" }} onClick={() => setshow3(false)}> Cancel
          </button>
          &nbsp; &nbsp;
          <button class="primary_btn" onClick={handleBuyPackage} > Confirm
          </button>
        </Modal.Body>
      </Modal>


    </div >



  );
}
