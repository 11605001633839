import React, {useState, useEffect } from 'react'
import { useCookies } from "react-cookie";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { accountReset } from "../redux/features/user/userslice"
import { getUserProfile } from "../redux/features/user/userslice";
import { getDefStore } from "../redux/features/defaultstore/defaultStoreSlice";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { getMyproduct } from "../redux/features/getmyProduct/myProductSlice";
import { Loginuser, getGeoInfoData, isValidCampign } from "../action/user.action";
import { setUserToken } from "../utils/cookies";
import { getNotify } from "../redux/features/notification/notificationSlice"
import { toastAlert } from "../utils/toast";

function UrlLogin() {
    const [cookies, setCookie, removeCookie] = useCookies(["name"]);
    const [validateError, setValidateError] = useState({});

    const { token } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        Removecookies()
        checkValidFor()
    }, [token])



    const Removecookies = async () => {

        removeCookie("userToken");
        removeCookie("loggedin");
        dispatch(accountReset(null))
    
    };

    const checkValidFor = async () => {
        try {
          if (token) {
            console.log("Hi");
    
              const payload = {
                refCode: token,
              };
              const check = await isValidCampign(payload);
              console.log(check, "check");
              var reqdata = {
                email: check.email,
                password: check.pwd,
                type: "Admin"
              };
              console.log(reqdata, 'reqdata');
              const result = await Loginuser(reqdata, dispatch);
              console.log(result,"result");
              if (result.status == false) {
                var validateError = {};
                validateError.email = result.message.email;
                validateError.password = result.message.password;
                setValidateError(validateError);
              } else {
                // router.push("/dashboard");
                setCookie("loggedin", true, { path: "/dashboard" });
    
                setUserToken(result.result.token, false);
    
                dispatch(getUserProfile());
                dispatch(getDefStore());
                dispatch(getUserStore());
                dispatch(getUserDraft());
                dispatch(getMyproduct());
                dispatch(getNotify());
    

                sessionStorage.removeItem("USER_AUTH_VERIFY_TOKEN_DATA");
                setTimeout(() => {
                  window.location.href = "/dashboard";
                }, 1000);
    
                toastAlert("success", "Logged In Successfully");
              }
            
          }
    
    
        } catch (err) {
          console.log(err);
        }
      };
    


    return (
      <>
        <div>loading</div>
        {validateError.password && (
          <span className="text-danger">{validateError.password}</span>
        )}
         {validateError.email && (
          <span className="text-danger">{validateError.email}</span>
        )}
        </>
    )
}

export default UrlLogin