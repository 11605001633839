import React, { useState } from "react";
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/index";
import { importToStore } from "../../action/user.action";
import { getUserProfile } from "../../redux/features/user/userslice";
import { getUserDraft } from "../../redux/features/draft/mydraftslice";
import { toastAlert } from "../../utils/toast";
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice";
import { validPositive } from "../../helper/validation";

function ImportProductPop(props) {
  const dispatch = useDispatch();

  const userstore = useSelector((state) => state.userstore);

  var { show, setshow, productInfo } = props;

  const [selectedValue, setSelectedValue] = useState(null);
  const [importAmt, setimportAmt] = useState("");
  const [validation, setvalidation] = useState({});

  const handleSelect = (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));
  };
  const handleClose = () => {
    setshow(false);
    setvalidation({});
    setimportAmt('')
  };

  const handlevalidation = () => {
    try {
      console.log(productInfo?.product?.product_cost, "product_cost");
      var validateError = {};
      if (selectedValue == null) {
        validateError.store = "Store Required";
      }
      if (importAmt.trim() == "") {
        validateError.amount = "Sell Cost Must not be Empty";
      }
      if (importAmt < Number(productInfo?.product?.product_cost)) {
        validateError.amount = "Sell cost must be Greater than Product cost";
      }

      setvalidation(validateError);
      return validateError;
    } catch (err) {
      console.log(err);
    }
  };

  const handleImport = async () => {
    try {
      const check = await handlevalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqdata = {
          productid: productInfo?.product_id,
          store_id: selectedValue?._id,
          sellcost: importAmt,
          draftid: productInfo?._id
        };

        const { status, message } = await importToStore(reqdata);
        if (status) {
          setSelectedValue(null)
          setimportAmt('')
          dispatch(getUserProfile());
          dispatch(getUserDraft());

          dispatch(getMyproduct());

          setshow(false);
          toastAlert("success", message);
        } else {
          toastAlert("error", message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(importAmt - productInfo?.product_cost, "surya");


  return (
    <>
      <Modal show={show} centered className="custom_modal" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div></div>
          <div className="categories categories_chg">
            <div className="row">
              <div className="col-lg-5">
                <div className="box">
                  <div className="img_box">
                    <img
                      src={`${config.API_URL}/product/${productInfo?.product?.product_image[0]}`}
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="box p-0 border-0">
                  <p className="sm_txt h-auto">
                    Id : {productInfo?.product_id}
                  </p>
                  <div className="bol dark">{productInfo?.product_title}</div>
                  <p className="sm_txt h-auto">
                    Created : {moment(productInfo.createdAt).format("lll")}
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="mem">Product cost :</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="mem">${productInfo?.product_cost} </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="mem">Import cost :</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="mem">${productInfo?.import_cost} </h5>
                    </div>
                  </div>
                  <div className="detail">
                    {/* <img src={ship} className="img-fluid" alt="img" /> */}
                    <span>Status:waiting</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <label className="sem sm_txt mb-3">Store Name</label>
          <div className="drp_dwn drp_dwn_hgt mb-4">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle className="d-flex justify-content-between">
                {selectedValue
                  ? ` ${selectedValue?.store_name?.charAt(0).toUpperCase() + selectedValue?.store_name?.slice(1)}`
                  : "Select Store"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                {userstore.length > 0 &&
                  userstore?.map((item) => (
                    <Dropdown.Item eventKey={JSON.stringify(item)}>
                      {item?.store_name?.charAt(0).toUpperCase() + item?.store_name?.slice(1) +
                        "-" +
                        item?.default_storeid?.Store_name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            {validation.store && (
              <span className="text-danger">{validation.store}</span>
            )}
          </div>

          <div className="input_box">
            <label className="sem sm_txt mb-3">Selling Price</label>
            <div class="input-group mb-3">
              <input
                type="text"
                className="form-control"
                name="text"
                placeholder="Enter Selling Amount"
                onInput={validPositive}
                onChange={(e) => {
                  setimportAmt(e.target.value);
                }}
              ></input>
            </div>
            {validation.amount && (
              <span className="text-danger">{validation.amount}</span>
            )}
          </div>

          <div className="input_box">
            <label className="sem sm_txt mb-3">Profit Per Order</label>
            <div class="input-group mb-3">
              <input
                type="text"
                className="form-control"
                name="text"
                placeholder="Enter Selling Amount"
                value={importAmt ? Math.abs(importAmt - productInfo?.product_cost) == productInfo?.product_cost ? 0 : (importAmt - productInfo?.product_cost).toFixed(2) : 0}

                readOnly
              ></input>
            </div>

          </div>

          <div className="add">
            <button className="primary_btn" onClick={handleImport}>
              {" "}
              +Import Store
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImportProductPop;
