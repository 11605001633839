import React, { useEffect, useState, useRef } from "react";
// img
import {
  Alert,
} from "react-bootstrap";
import browser from "browser-detect";

import hide from "../assets/images/hide.svg";
import login from "../assets/images/login.svg";
import login_logo from "../assets/images/login_logo.svg";
import lock from "../assets/images/lock.svg";
import email from "../assets/images/email.svg";
import eye from "../assets/images/eye.svg";
import { ValidateUserName, ValidateEmail } from "../helper/validation";
import { Loginuser, getGeoInfoData, isValidCampign } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { useCookies } from "react-cookie";
import { setUserToken } from "../utils/cookies";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { getDefStore } from "../redux/features/defaultstore/defaultStoreSlice";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { getMyproduct } from "../redux/features/getmyProduct/myProductSlice";
import { useParams, useLocation } from "react-router-dom";
// import {} from 'react-router-dom';
import { getNotify } from "../redux/features/notification/notificationSlice"

import { accountReset } from "../redux/features/user/userslice"



const initialstate = {
  emailid: "",
  password: "",
};

export default function Login() {
  const { authToken } = useParams();



  const dispatch = useDispatch();
  const location = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies(["name"]);

  const [showPassword, setShowPassword] = useState(false);
  const [validateError, setValidateError] = useState({});
  const [formdata, setformdata] = useState(initialstate);
  const [loginHistory, setLoginHistory] = useState({});

  const [rememberMe, setRememberMe] = useState(false);

  const [mailactivation, setmailactivation] = useState(
    location.state == undefined ? "done" : location.state.result,
  );

  console.log(authToken, 'sakthibala');

  const { emailid, password } = formdata;

  useEffect(() => {
    checkValidFor();
  }, [authToken]);

  useEffect(() => {
    getGeoInfo();
  }, []);


  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipAddress: result.ip,
        regionName: result.region,
        broswerName: browserResult.name,
        isMobile: browserResult.mobile,
        os: browserResult.os,
      });
    } catch (err) {
      console.log(err);
    }
  };


  const Removecookies = async () => {
    removeCookie("userToken");
    removeCookie("loggedin");
    dispatch(accountReset(null));
    // window.location.href = "/login";
    return true
  };


  const checkValidFor = async () => {
    try {
      if (authToken) {
        console.log("Hi");

        const payload = {
          refCode: authToken,
        };
        const check = await isValidCampign(payload);
        console.log(check, "check");
        var reqdata = {
          email: check.email,
          password: check.pwd,
          type: "Admin"
        };
        console.log(reqdata, 'reqdata');
        const result = await Loginuser(reqdata, dispatch);
        if (result.status == false) {
          var validateError = {};
          validateError.email = result.message.email;
          validateError.password = result.message.password;
          setValidateError(validateError);
        } else {
          // router.push("/dashboard");
          setCookie("loggedin", true, { path: "/dashboard" });

          setUserToken(result.result.token, false);

          dispatch(getUserProfile());
          dispatch(getDefStore());
          dispatch(getUserStore());
          dispatch(getUserDraft());
          dispatch(getMyproduct());
          dispatch(getNotify());


          sessionStorage.removeItem("USER_AUTH_VERIFY_TOKEN_DATA");
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);

          toastAlert("success", "Logged In Successfully");
        }

      }


    } catch (err) {
      console.log(err);
    }
  };



  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };



  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formdata, ...{ [name]: value } };
    setformdata(formData);
  };


  const Fromvalidation = async () => {
    try {
      var validateError = {};

      if (emailid.trim() == "") {
        validateError.email = "Email Required";
      } else if (!ValidateEmail(emailid)) {
        validateError.email = "Invalid Email Address";
      }

      if (password.trim() == "") {
        validateError.password = "Password Required";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  
  const handleSubmit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqdata = {
          email: emailid,
          password: password,
          loginHistory
        };

        const result = await Loginuser(reqdata, dispatch);

        console.log(result,'login');
        if (result.status == false) {
          var validateError = {};
          validateError.email = result.message.email;
          validateError.password = result.message.password;
          setValidateError(validateError);
        } else {
          // router.push("/dashboard");
          setCookie("loggedin", true, { path: "/dashboard" });

          setUserToken(result.result.token, rememberMe);

          dispatch(getUserProfile());
          dispatch(getDefStore());
          dispatch(getUserStore());
          dispatch(getUserDraft());
          dispatch(getMyproduct());
          sessionStorage.removeItem("USER_AUTH_VERIFY_TOKEN_DATA");
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);

          toastAlert("success", "Logged In Successfully");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(loginHistory, "loginHistory");

  return (
    <div className="login">
      <div className="left">
        <div className="w-75">
          <h2>
            Login with <span> ShoppyAI</span>
          </h2>
          {mailactivation === true && (
            <Alert key={"success"} variant={"success"} className="p-2">
              <i className="me-2 fa-solid fa-circle-check"></i>
              Your Email has been activated. Please login your account!
            </Alert>
          )}
          {mailactivation === false && (
            <Alert key={"danger"} variant={"danger"} className="p-2">
              <i className="me-2 fa-solid fa-circle-xmark"></i>
              Your account has been activated already. Please login your
              account!
            </Alert>
          )}
          <div className="input_box mb-4">
            <label className="mb-3">Email Address</label>
            <div class="input-group">
              <span class="input-group-text pe-1">
                <img src={email} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                name="emailid"
                placeholder="Enter your Email Address"
                onChange={handleChange}
              />
            </div>{" "}
            {validateError.email && (
              <span className="text-danger">{validateError.email}</span>
            )}
          </div>
          <div className="input_box mb-4">
            <label className="mb-3">Password</label>
            <div class="input-group">
              <span class="input-group-text pe-1">
                <img src={lock} className="img-fluid" alt="img" />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                class="form-control py-3"
                name="password"
                placeholder="Enter your Password"
                onChange={handleChange}
              />

              <span
                class="input-group-text cursor-pointer"
                onClick={handleTogglePassword}
              >
                <img
                  src={showPassword ? eye : hide}
                  className="img-fluid"
                  alt="img"
                />
              </span>
            </div>
            {validateError.password && (
              <span className="text-danger">{validateError.password}</span>
            )}
          </div>
          <div class="form-check mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label class="form-check-label" for="flexCheckChecked">
              Remember me
            </label>
          </div>

          <a href="/forgot"
            className="link mb-4"
            style={{ color: "blue" }}
          >
            Forgot Password?
          </a>


          <button
            className="primary_btn mb-4 w-100 rounded-2"
            onClick={handleSubmit}
          >
            Login
          </button>
          <div className="signup text-center">
            Don't have an account? <a href="/register"> Register here</a>
          </div>
        </div>
      </div>
      <div className="right d-none d-lg-flex">
        <div className="text-center">
          <img src={login_logo} className="img-fluid mb-4" alt="img" />
          <p className="para mb-5">
            Log in now to ShoppyAI to better manage your dropshipping account
            and increase your profits!
          </p>
          <img src={login} className="img-fluid" alt="img" />
        </div>
      </div>
    </div>
  );
}
