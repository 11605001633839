import React, { useEffect, useState } from "react";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";
import { getshipPack, shipplanSubscribtion } from "../action/user.action.js"

import { Modal, Dropdown, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { toastAlert } from "../utils/toast.js";
import config from "../config/index.js"

export default function ShippingPackage() {
    const dispatch = useDispatch();

    const [packdata, setpackdata] = useState([])
    const [show3, setshow3] = useState(false)
    const [show, setshow] = useState(100000)
    const [selectpack, setselectpack] = useState([])

    const user = useSelector((state) => state.account);



    useEffect(() => {
        getpackage()
    }, [])


    const getpackage = async () => {
        try {
            var { status, result } = await getshipPack()
            if (result) {
                setpackdata(result)

            }
        } catch (err) {
            console.log(err);

        }
    }

    var color = ["", "rose", "green", "blue"]
    var colors = ["yellow", "green", "black", "grey", "lightgrey", "violet"]

    const handleOpen = (item) => {
        setshow3(true)
        setselectpack(item)
    }




    const handleBuyPackage = async () => {
        try {

            let reqdata = {
                planid: selectpack._id
            }
            let { status, message } = await shipplanSubscribtion(reqdata)

            if (status) {
                setshow3(false)
                dispatch(getUserProfile());
                toastAlert("success", message)
            } else {
                toastAlert("error", message)
            }

        } catch (err) {
            console.log(err);
        }

    }

    const handleclick = (index) => {
        setshow(index);
        if (show == index) {
            setshow(10000);
        }
    }



    return (
        <div className="page_wrapper" >
            {/* <Sidebar navLink={"/shipping-package"} /> */}
            <div  >
                {/* <Navbarinner /> */}
                <div >
                    <center>
                        <h2 className="h3tag bdr mb-5" >Shipping Package</h2>

                    </center>

                    <div className="plan_box">
                      
                        <div className="grid">
                            {packdata && packdata.map((item, index) => (

                                <div className={`box`} >
                                    <div className={`abv_box ${item.color}`}>
                                    <p> <strong style={{ fontSize: 'x-large' }}>{item?.package_name}</strong></p>
                                        <h2>${item?.package_amount}</h2>

                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" onClick={() => handleclick(index)}>
                                                <span>Billed Monthly</span>
                                            </Dropdown.Toggle>


                                        </Dropdown>
                                        {user.plan_ship_type != "none" && user.plan_ship_id == item._id ?

                                            <button className="primary_btn">Subscribed</button> :

                                            <button className="primary_btn" onClick={() => { handleOpen(item) }}>Buy Now</button>

                                        }
                                    </div>
                                    <div className={`blw_box ${show == index ? "show" : "hide"}`} >

                                        <ul>
                                            <li>
                                                <img
                                                    style={{ width: "130px" }}
                                                    src={`${config.API_URL}/package/${item.logo}`}
                                                />
                                            </li>
                                            <li>
                                                <strong>{item?.description}</strong>
                                            </li>

                                            <li>
                                                <strong>{item?.shipping_days}</strong>{" "}
                                                Business Days for Shipping
                                            </li>
                                            <li>Air Express hubs :{""}
                                                <strong>{item?.air_express}</strong>

                                            </li>
                                            <li>Average daily package volume:  {""}
                                                <strong>{item?.average_package}</strong>
                                            </li>
                                            <li > Airports served :{""} <strong>{item?.airports}</strong>
                                            </li>
                                            <li> <strong>{item?.authorized}</strong>  Authorized Ship Center® (FASC) and Alliance Partners.
                                            </li>


                                        </ul>


                                    </div>
                                </div>))}



                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show3} centered size="sm" className="custom_modal" >
                <Modal.Header className="mb-3"  >
                    <div className="cursor-pointer" >
                        <h6>Are You sure to Buy <strong>{selectpack.package_name}</strong> Package With
                            <strong> {selectpack.package_amount}</strong> USD?</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <button class="secondary_btn" style={{ backgound: "none !important" }} onClick={() => setshow3(false)}> Cancel
                    </button>
                    &nbsp; &nbsp;
                    <button class="primary_btn" onClick={handleBuyPackage} > Confirm
                    </button>
                </Modal.Body>
            </Modal>


        </div >



    );
}
