import React, { useState } from "react";
import { InputGroup, Form, Accordion } from "react-bootstrap";
// img
import usr from "../assets/images/usr.svg";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";

import SupportCreate from "../components/support/SupportCreate"
import SupportList from "../components/support/SupportList";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"

export default function Support() {



    return (
        <>  <ScrollToTopOnMount />

            <div className="page_wrapper" >
                <Sidebar navLink={"/support-ticket"} />
                <div className="w-100" >
                    <Navbarinner />
                    <div className="right_wrapper" >
                        <div className="row">
                            <div className="col-lg-4">

                                <SupportCreate />
                            </div>
                            <div className="col-lg-8">
                                <SupportList />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
