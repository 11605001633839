import React, { useEffect, useState } from "react";
import left_arw from "../../assets/images/left_arw.svg";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import { getMyDraft } from "../../action/user.action";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteDraft } from "../../action/user.action";
import { toastAlert } from "../../utils/toast";

import { bulkImportProduct } from "../../action/user.action";
import ImportProductPop from "../importProduct/ImportProductPop";

import { getUserProfile } from "../../redux/features/user/userslice";
import { getUserDraft } from "../../redux/features/draft/mydraftslice";
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice";
import { validPositive } from "../../helper/validation";

function DraftPop(props) {
  const dispatch = useDispatch();

  const [show, setshow] = useState(false);
  const [show4, setshow4] = useState(false);

  const [productInfo, setproductInfo] = useState([]);
  const [show3, setshow3] = useState(false);
  const [draftId, setdraftId] = useState("");
  const [bulkimport, setbulkimport] = useState(false);
  const [selldata, setselldata] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  var { show2, handleClose2 } = props;

  const userdraft = useSelector((state) => state?.userdraft);
  const myproduct = useSelector((state) => state?.myproduct);
  const userstore = useSelector((state) => state?.userstore);

  console.log(userstore, userdraft, myproduct, 'sakthibala');

  const HandleDelete = async (id) => {
    try {
      var { status, message } = await deleteDraft({ draftid: id });
      if (status) {
        setshow3(false);
        dispatch(getUserDraft());
        toastAlert("success", message);
      } else {
        toastAlert("error", message);
      }
    } catch (err) { }
  };

  const handleopen = (id) => {
    setshow3(true);
    setdraftId(id);
  };

  const handleOpen = (data) => {
    try {
      setproductInfo(data);
      setshow(true);
    } catch (err) { }
  };

  const handleClose = () => {
    setshow(false);
  };

  const handleBulkChanges = (e, draftid) => {
    var { value, name } = e.target;

    var foundIndex = selldata.findIndex((x) => x.draftid == draftid);

    if (foundIndex != -1) {
      let newArr = [...selldata];
      newArr[foundIndex].sellprice = value;
      setselldata(newArr);
    } else {
      setselldata((state) => [
        ...state,
        { sellprice: value, product_id: name, draftid: draftid },
      ]);
    }
  };

  const handleBulkImport = async () => {
    try {
      if (selldata.length == 0) {
        toastAlert("error", "No product has Selling Price");
        return false;
      }

      if (selectedValue == null) {
        toastAlert("error", "Please select Your store");
        return false;
      }

      const filteredData = selldata.filter(
        (selldata) => selldata.sellprice !== "" && selldata.sellprice != 0
      );

      var reqdata = {
        importdata: filteredData,
        store_id: selectedValue?._id,
      };

      var { status, message } = await bulkImportProduct(reqdata);

      if (status) {
        dispatch(getUserProfile());
        dispatch(getUserDraft());

        dispatch(getMyproduct());
        setselldata([]);

        setshow4(false);
        setbulkimport(false);
        toastAlert("success", message);
      } else {
        toastAlert("error", message);
      }
    } catch (err) { }
  };

  const columns = [
    {
      name: "Product",
      selector: (row) => row.product_title,
      width: "210px",
      cell: (row) => <div className="sem">{row.product_title}</div>,
    },
    {
      name: "Created",
      selector: (row) => row.createdAt,
      width: "170px",
      cell: (row) => (
        <div className="sem">{moment(row.createdAt).format("lll")}</div>
      ),
    },
    // {
    //     name: 'Source Item ID',
    //     selector: row => row.SourceItemID,
    // },
    // {
    //     name: 'Sell Item ID',
    //     selector: row => row.SellItemID,
    // },
    {
      name: "Actual Price",
      width: "120px",
      selector: (row) => row.product_cost,
      cell: (row) => <div className="sem">${row.product_cost}</div>,
    },
    {
      name: "Import Price",
      label: "sdf",
      width: "150px",

      selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${row.import_cost}</div>,
    },
    {
      name: "Average Market price",
      width: "180px",
      // selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${(Number(row.product_cost) + (Number(row.product_cost) * 30) / 100).toFixed(2)}</div>,
    },
    // {

    //     name: 'Store',
    //     selector: row => row.Store,
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <div className="sem">{row.status}</div>,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <div class="add">
          {
            userstore.length > 0 ?
              <button class="primary_btn" onClick={() => handleOpen(row)}>
                {" "}
                + Add Store
              </button> :
              <div className="sem">Create Store </div>
          }
        </div>
      ),
    },
    {
      cell: (row) => (
        <div class="add">
          <button class="primary_btn" onClick={() => handleopen(row._id)}>
            {" "}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "Product",
      selector: (row) => row.product_title,
      width: "210px",
      cell: (row) => <div className="sem">{row.product_title}</div>,
    },
    {
      name: "Created",
      selector: (row) => row.createdAt,
      width: "170px",
      cell: (row) => (
        <div className="sem">{moment(row.createdAt).format("lll")}</div>
      ),
    },
    // {
    //     name: 'Source Item ID',
    //     selector: row => row.SourceItemID,
    // },
    // {
    //     name: 'Sell Item ID',
    //     selector: row => row.SellItemID,
    // },
    {
      name: "Actual Price",
      width: "120px",
      selector: (row) => row.product_cost,
      cell: (row) => <div className="sem">${row.product_cost}</div>,
    },
    {
      name: "Import Price",
      width: "150px",
      selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${row.import_cost}</div>,
    },
    {
      name: "Average Market price",
      width: "180px",
      // selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${(Number(row.product_cost) + (Number(row.product_cost) * 30) / 100).toFixed(2)}</div>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <div className="sem">{row.status}</div>,
    },
    {
      name: "Selling price",
      width: "150px",
      selector: (row) => row.status,
      cell: (row) => (
        <div class="input-group mb-3">
          <input
            type="text"
            className="form-control mt-4"
            placeholder="Sell Price"
            name={row.product_id}
            onInput={validPositive}
            onChange={(e) => handleBulkChanges(e, row._id)}
          ></input>
        </div>
      ),
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <div class="add">
          <button class="primary_btn" onClick={() => handleopen(row._id)}>
            {" "}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      ),
    },
  ];

  const columns1 = [
    {
      name: "Product",
      selector: (row) => row.product_title,
      width: "210px",
      cell: (row) => <div className="sem">{row.product_title}</div>,
    },
    {
      name: "Created",
      selector: (row) => row.createdAt,
      width: "170px",
      cell: (row) => (
        <div className="sem">{moment(row.createdAt).format("lll")}</div>
      ),
    },
    {
      name: "Product Id",
      width: "240px",
      selector: (row) => row.product_id,
    },
    // {
    //     name: 'Sell Item ID',
    //     selector: row => row.SellItemID,
    // },

    {
      name: "Sell Price",
      width: "150px",
      selector: (row) => row.sell_cost,
      cell: (row) => <div className="sem">${row.sell_cost}</div>,
    },
    {
      name: "Import Price ",
      width: "150px",
      selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${row.import_cost}</div>,
    },
    {
      name: "Average Market price",
      width: "180px",
      // selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">
        {/* {console.log(row ,'pppppppppppppppppppppp')} */}
        ${(Number(row.actual_cost) + ((Number(row.actual_cost) * 30) / 100)).toFixed(2)}</div>,
    },
    {
      name: "Reason",
      width: "180px",
      selector: (row) => row.reason,
      cell: (row) => <div className="sem">{row.reason == 'insufficient funds' ? 'Insufficient Funds' : row.reason || "-"}</div>,
    },

    {
      name: "Status",
      width: "200px",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="sem">
          {row.status == "completed"
            ? "Completed"
            : row.status == "pending"
              ? "Pending"
              : "Failed"}
        </div>
      ),
    },
  ];

  const CustomNoDataMessage = () => (
    <div className="text-center py-5 my-5">
      <p>No data</p>
    </div>
  );

  const handleCheckbox = (e) => {
    if (e.target.value == "true") {
      setbulkimport(false);
    } else {
      setbulkimport(true);
    }
  };

  const handleSelect = (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));
  };

  const handleClose4 = () => {
    setshow4(false);
  };

  return (
    <>
      <Modal
        show={show2}
        centered
        size="xl"
        className="custom_modal"
        onHide={handleClose2}
      >
        <Modal.Header className="mb-3" closeButton>
          <div className="cursor-pointer">
            <img src={left_arw} className="img-fluid me-2 mb-1" alt="logo" />
            Back
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="import_box">
            <h6>Draft Product</h6>
            <div className="custom_tab">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav>
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      All Product ({userdraft.length}){" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      Failed ({myproduct?.failed?.length}){" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      Successful ({myproduct?.completed?.length})
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Scrollbars style={{ width: "100%", height: 400 }}>
                      <div className="custom_table_chg onetime">

                        {
                          userdraft.length > 0 ?
                            <>
                              <>
                                Actions - Fast Import : &nbsp;
                                {
                                  userstore.length == 0 ? "Create Store" : <input
                                    type="checkbox"
                                    value={bulkimport}
                                    checked={bulkimport}
                                    onClick={handleCheckbox}
                                  />
                                }

                              </>
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              {bulkimport ? (
                                <>
                                  <button class="primary_btn" onClick={() => setshow4(true)}>
                                    Import
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </> : ''

                        }

                        <DataTable
                          columns={bulkimport ? columns2 : columns}
                          data={userdraft}
                          noDataComponent={<CustomNoDataMessage />}
                        // pagination
                        // paginationPerPage={5}
                        // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </Scrollbars>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Scrollbars style={{ width: "100%", height: 400 }}>
                      <div className="custom_table_chg onetime1">
                        <DataTable
                          columns={columns1}
                          data={myproduct?.failed}
                          noDataComponent={<CustomNoDataMessage />}
                          pagination
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </Scrollbars>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Scrollbars style={{ width: "100%", height: 400 }}>
                      <div className="custom_table_chg onetime1">
                        <DataTable
                          columns={columns1}
                          data={myproduct?.completed}
                          noDataComponent={<CustomNoDataMessage />}
                          pagination
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </Scrollbars>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">fourth tab content</Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          {/* <div class="add"><button class="primary_btn"> +  Add Store</button></div> */}
        </Modal.Body>
      </Modal>

      <Modal show={show3} size="sm" className="custom_modal">
        <Modal.Header className="mb-3">
          <div className="cursor-pointer">
            <h6>Are You Sure To Remove?</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            class="secondary_btn"
            style={{ backgound: "none !important" }}
            onClick={() => setshow3(false)}
          >
            {" "}
            Cancel
          </button>
          &nbsp; &nbsp;
          <button class="primary_btn" onClick={() => HandleDelete(draftId)}>
            {" "}
            Confirm
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show4}
        centered
        size="sm"
        className="custom_modal"
        onHide={handleClose4}
      >
        <Modal.Header className="mb-3" closeButton>
          <div className="cursor-pointer">
            <h6>Select Your Store</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <label className="sem sm_txt mb-3">Select Store</label>
          <div className="drp_dwn drp_dwn_hgt mb-4">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle className="d-flex justify-content-between">
                {selectedValue
                  ? ` ${selectedValue?.store_name}`
                  : "Select Store"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                {userstore.length > 0 &&
                  userstore?.map((item) => {
                    if (item?.status == "active")
                      return (
                        <Dropdown.Item eventKey={JSON.stringify(item)}>
                          {item?.store_name +
                            "-" +
                            item?.default_storeid?.Store_name}
                        </Dropdown.Item>
                      );
                  })}
              </Dropdown.Menu>
            </Dropdown>
            {/* {validation.store && (
                            <span className="text-danger">
                                {validation.store}
                            </span>
                        )} */}
          </div>
          <button
            class="primary_btn"
            style={{ backgound: "none !important" }}
            onClick={handleBulkImport}
          >
            {" "}
            Confirm
          </button>
        </Modal.Body>
      </Modal>

      <ImportProductPop
        show={show}
        setshow={setshow}
        handleClose={handleClose}
        productInfo={productInfo}
      />
    </>
  );
}

export default DraftPop;
