import React, { useState, useEffect } from 'react'
import { Modal, Dropdown, Nav, Tab } from 'react-bootstrap';
import config from "../../config/index"
import moment from 'moment';
import { validPositive } from "../../helper/validation"
import { isEmpty } from '../../helper/validation';
import { EditSellPrice } from "../../action/user.action"
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice"
import { toastAlert } from "../../utils/toast";

import { useDispatch, useSelector } from "react-redux";

function EditSellCostPop(props) {
    const dispatch = useDispatch();

    var { show, setshow, productInfo } = props

    const [importAmt, setimportAmt] = useState();
    const [validation, setvalidation] = useState({});

    useEffect(() => {
        setimportAmt(productInfo.sell_cost)
    }, [productInfo])




    const handleClose = () => {
        setshow(false)
        setvalidation({})
        setimportAmt('')
    }

    const handlevalidation = () => {
        try {

            var validateError = {};

            console.log(importAmt, "importAmt");

            if (isEmpty(importAmt)) {
                validateError.amount = "Sell Cost Must not be Empty";
            }
            if (importAmt < Number(productInfo?.product?.product_cost)) {
                validateError.amount = "Sell cost must be Greater than Product cost";
            }

            setvalidation(validateError);
            return validateError;
        } catch (err) {
            console.log(err);
        }
    }





    const handleSubmit = async () => {
        try {
            const check = handlevalidation();
            var errorsSize = Object.keys(check).length;
            if (errorsSize == 0) {

                var reqdata = {
                    id: productInfo._id,
                    sellprice: importAmt
                }

                var { status, message } = await EditSellPrice(reqdata)
                console.log(status, "status");
                if (status) {
                    dispatch(getMyproduct());
                    setshow(false)
                    toastAlert("success", "Edited Successfully");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            }

        } catch (err) {
            console.log(err);

        }
    }




    return (
        <>
            <Modal show={show} centered className="custom_modal" onHide={handleClose}>
                <Modal.Header closeButton  >
                    <Modal.Title>Edit Sell Price</Modal.Title>
                </Modal.Header>



                <Modal.Body>


                    <div>

                    </div>
                    <div className='categories categories_chg'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className="box">
                                    <div className="img_box">
                                        <img src={`${config.API_URL}/product/${productInfo?.product?.product_image[0]}`} class="img-fluid" alt="img" />
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className="box p-0 border-0">
                                    <p className="sm_txt h-auto" >Id : {productInfo?.product_id}</p>
                                    <div className="bol dark" >{productInfo?.product_title}</div>
                                    <p className="sm_txt h-auto" >Created : {moment(productInfo?.createdAt).format("lll")}</p>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >Product cost :</h5>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >${productInfo?.actual_cost} </h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >Import cost :</h5>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h5 className='mem' >${productInfo?.import_cost} </h5>
                                        </div>
                                    </div>
                                    <div className="detail" >
                                        {/* <img src={ship} className="img-fluid" alt="img" /> */}
                                        <span>Status:waiting</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="input_box">
                        <label className="sem sm_txt mb-3">Selling Price</label>
                        <div class="input-group mb-3">
                            <input type="text" className="form-control" name="text" placeholder="Enter Selling Amount"
                                onInput={validPositive}
                                value={importAmt}
                                onChange={(e) => { setimportAmt(e.target.value) }}

                            ></input>
                        </div>
                        {validation.amount && (
                            <span className="text-danger">
                                {validation.amount}
                            </span>
                        )}
                    </div>


                    <div className="add">
                        <button className="primary_btn" onClick={handleSubmit} >Confirm</button>
                    </div>
                </Modal.Body>
            </Modal >

        </>

    )
}

export default EditSellCostPop