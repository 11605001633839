import React, { useEffect, useState, useRef } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { Modal, Dropdown, Row, Col, Form } from 'react-bootstrap';
// img 
import down from "../assets/images/down.svg";
import calender from "../assets/images/calender.svg";
import profit from "../assets/images/profit.svg";
import tot_order from "../assets/images/tot_order.svg";
import revenue from "../assets/images/revenue.svg";
import new_products from "../assets/images/new_products.svg";
import basket from "../assets/images/basket.svg";
import tag from "../assets/images/tag.svg";
import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useNavigate } from "react-router-dom";
import { Updatepayment, getdashBoardOrder } from "../action/user.action.js";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice.js";
import moment from "moment";
import { ValidateEmail, validSpecialPositive, validSwift, ValidateUserName, validPositive } from "../helper/validation"
import { toastAlert } from "../utils/toast";

import verifyedImg from '../assets/images/68208085d424bbb26580eb7f1c4af01c5ed92e87ee99fd972f2e7e24eeee5a68-removebg-preview.png'


import { setBankDetail } from "../action/user.action"
import axios from "axios";

let initialstate = {
  email: "",
  phone: "",
  nameonBank: "",
  bankName: "",
  accNo: "",
  ifscCode: ""
}


export default function Kyc() {
  const user = useSelector((state) => state.account);

  console.log(user, 'useSelector');

  const [formdata, setfromdata] = useState(initialstate)
  const [ValidateError, setValidateError] = useState({});
  const { email, phone, nameonBank, bankName, accNo, ifscCode } = formdata



  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formdata, ...{ [name]: value } };
    setfromdata(formData);
    // if (name == 'ifscCode') {
    //   try {

    //     const data = await axios.get(`https://bank-apis.justinclicks.com/API/V1/IFSC/${value}/`)

    //     console.log(data, 'ifscCode');
    //     if (data) {
    //       setfromdata({ ...formdata, ...{ ifscCode: value, bankName: data.data.BANK } })
    //     }
    //     setValidateError({})
    //   } catch (error) {
    //     setfromdata({ bankName: '' })
    //     setValidateError({ ifscCode: 'Invalid IFSC Code' })
    //   }
    // } else {

    // }
  }



  const Fromvalidation = async () => {
    try {
      var validateError = {};
      const regex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
      const regex1 = /^([A-Z]{2}\d{2})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})$/;
      console.log(regex1.test(accNo), "46");


      if (email.trim() == "") {
        validateError.email = "Email Required";
      } else if (!ValidateEmail(email)) {
        validateError.email = "Invalid Email Address";
      }
      if (phone.trim() == "") {
        validateError.phone = "Mobile Number Required";
      }
      // if()
      if (nameonBank.trim() == "") {
        validateError.nameonBank = "Name Required";
      } else if (!ValidateUserName(nameonBank)) {
        validateError.nameonBank = "Invalid Name";
      }


      if (bankName.trim() == "") {
        validateError.bankName = "Bank Name Required";
      } else if (!ValidateUserName(bankName)) {
        validateError.bankName = "Invalid Bank Name";
      }

      if (accNo.trim() == "") {
        validateError.accNo = "IBAN Required";
      } else if (!(accNo).match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/)) {
        validateError.accNo = "Invalid IBAN";
      }

      if (ifscCode.trim() == "") {
        validateError.ifscCode = "Swift bic Required";
      } else if (!regex.test(ifscCode)) {
        validateError.ifscCode = "Invalid Swift bic";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
    }
  };



  const handleSumbit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqdata = {
          email, phone, nameonBank, bankName, accNo, ifscCode
        }
        var { status, message } = await setBankDetail(reqdata)
        if (status) {
          toastAlert("success", "Bank Details Uploaded Successfully");
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        // else {
        //   // var validateError = {};
        //   // validateError.oldPassword = error.oldPassword;
        //   // validateError.newPassword = error.newPassword;
        //   // validateError.conNewPassword = error.confirmNewPassword;
        //   // setValidateError(validateError);
        // }


      }


    } catch (err) {
      console.log(err);
    }

  }

  console.log(formdata, 'formdata');

  return (
    <div className="page_wrapper" >
      <Sidebar />
      <div className="w-100" >
        <Navbarinner />


        {user?.bankstatus ?
          <div className="right_wrapper" >
            <h2 class="h3tag bdr mb-3">Bank Details
            </h2>
            <center>
              {user?.kycstatus == 'approved' ?
                <h3 style={{ color: "#00e0ba", fontFamily: "-webkit-body" }}>
                  <img src={verifyedImg} alt="VERIFIED SUCCESSFULLY"/>
                  
                </h3> :
                user?.kycstatus == 'pending' ?
                  < h3 >
                    Successfully Uploaded, Waiting for Verification
                  </h3> :
                  < h3 >
                    Your Application is Rejected By The Admin
                  </h3>
              }
            </center>
          </div>
          :
          <>
            {
              user?.kycstatus == 'rejected' ?
                <div className="right_wrapper" >

                  < h5 className="alert alert-danger">
                    Your Application is Rejected By The Admin : {user?.userBankdetails?.reason}
                  </h5>
                  <h2 class="h3tag bdr mb-3">Bank Details
                  </h2>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="kyc_box" >
                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Email</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="Enter Your Email"
                              onChange={handleChange}

                            />
                          </div>
                          {ValidateError.email && (
                            <span className="text-danger">{ValidateError.email}</span>
                          )}
                        </div>


                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Phone</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              placeholder="Enter Your Phone Number"
                              onInput={validPositive}
                              onChange={handleChange}
                            />
                          </div>
                          {ValidateError.phone && (
                            <span className="text-danger">{ValidateError.phone}</span>
                          )}
                        </div>

                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Name on Bank</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="nameonBank"
                              placeholder="Enter Holder Name" onChange={handleChange}
                            />
                          </div>
                          {ValidateError.nameonBank && (
                            <span className="text-danger">{ValidateError.nameonBank}</span>
                          )}
                        </div>




                        <div className="input_box">
                          <label className="mb-2 sm_txt" >IBAN</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="accNo"
                              placeholder="Enter IBAN" onChange={handleChange}
                            />
                          </div>
                          {ValidateError.accNo && (
                            <span className="text-danger">{ValidateError.accNo}</span>
                          )}
                        </div>

                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Swift bic</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="ifscCode"
                              placeholder="Enter Swift bic" onChange={handleChange}
                            />
                          </div>
                          {ValidateError.ifscCode && (
                            <span className="text-danger">{ValidateError.ifscCode}</span>
                          )}
                        </div>
                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Bank Name</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="bankName"
                              placeholder="Enter Bank name"
                              defaultValue={bankName}
                              // disabled
                              onChange={handleChange}
                            />
                          </div>
                          {ValidateError.bankName && (
                            <span className="text-danger">{ValidateError.bankName}</span>
                          )}
                        </div>

                        <button className="primary_btn" onClick={handleSumbit}>Submit</button>

                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                </div>
                :
                <div className="right_wrapper" >
                  <h2 class="h3tag bdr mb-3">Bank Details
                  </h2>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="kyc_box" >
                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Email</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="Enter Your Email"
                              onChange={handleChange}

                            />
                          </div>
                          {ValidateError.email && (
                            <span className="text-danger">{ValidateError.email}</span>
                          )}
                        </div>


                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Phone</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              placeholder="Enter Your Phone"
                              onInput={validPositive}
                              onChange={handleChange}
                            />
                          </div>
                          {ValidateError.phone && (
                            <span className="text-danger">{ValidateError.phone}</span>
                          )}
                        </div>

                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Name on Bank</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="nameonBank"
                              placeholder="Enter Holder Name" onChange={handleChange}
                            />
                          </div>
                          {ValidateError.nameonBank && (
                            <span className="text-danger">{ValidateError.nameonBank}</span>
                          )}
                        </div>




                        <div className="input_box">
                          <label className="mb-2 sm_txt" >IBAN</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="accNo"
                              placeholder="Enter IBAN" onChange={handleChange}
                            />
                          </div>
                          {ValidateError.accNo && (
                            <span className="text-danger">{ValidateError.accNo}</span>
                          )}
                        </div>

                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Swift bic</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="ifscCode"
                              placeholder="Enter Swift bic" onChange={handleChange}
                            />
                          </div>
                          {ValidateError.ifscCode && (
                            <span className="text-danger">{ValidateError.ifscCode}</span>
                          )}
                        </div>

                        <div className="input_box">
                          <label className="mb-2 sm_txt" >Bank Name</label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={bankName}
                              name="bankName"
                              placeholder="Enter Bank Name"
                              // disabled
                              onChange={handleChange}
                            />
                          </div>
                          {ValidateError.bankName && (
                            <span className="text-danger">{ValidateError.bankName}</span>
                          )}
                        </div>

                        {/* </div> */}
                        <button className="primary_btn" onClick={handleSumbit}>Submit</button>

                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                </div>

            }
          </>

        }

      </div >
    </div >
  );
}
