import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import io from "socket.io-client";
import config from "../config";
// img
import w1 from "../assets/images/w1.svg";
import w2 from "../assets/images/w2.svg";
import w3 from "../assets/images/w3.svg";
import w4 from "../assets/images/w4.svg";
import robo from "../assets/images/robo.svg";
import t1 from "../assets/images/t1.gif";
import t2 from "../assets/images/t2.gif";
import t3 from "../assets/images/t3.gif";
import t4 from "../assets/images/t4.gif";
import t5 from "../assets/images/t5.gif";
import linearw from "../assets/images/line-arw.svg";
import support_img from "../assets/images/support_img.svg";
import rightarw from "../assets/images/rightarw.svg";
import quote from "../assets/images/quote.svg";
import { setUserToken } from "../utils/cookies";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice";
import { getDefStore } from "../redux/features/defaultstore/defaultStoreSlice";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { getMyproduct } from "../redux/features/getmyProduct/myProductSlice";
import { toastAlert } from "../utils/toast";
import { useCookies } from "react-cookie";
import { getAllFaq } from "../action/user.action.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const dispatch = useDispatch();

  const [faq, setFaq] = useState([])

  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  useEffect(() => {
    AOS.init();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {
    console.log("Effec");
    const socket = io.connect(config.API_URL);
    socket.on("checkReDir", function (data) {
      console.log(data, "DDDDDDDDDDDDDDD");
      if (data.token) {
        setCookie("loggedin", true, { path: "/dashboard" });
        setUserToken(data.token);
        dispatch(getUserProfile());
        dispatch(getDefStore());
        dispatch(getUserStore());
        dispatch(getUserDraft());
        dispatch(getMyproduct());

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);

        toastAlert("success", "Logged In Successfully");
      }
    });
  }, []);


  const reDirectLinks = () => {
    window.location.href = "/login";
  }


  const fetchData = async () => {

    try {

      const { status, result } = await getAllFaq()

      console.log(status, result, '1111');
      if (status) {
        setFaq(result)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header" name="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="box">
                <div data-aos="fade-up" data-aos-duration="1000">
                  <h1>
                    Build your drop shipping business with <span>ShoppyAI</span>
                  </h1>
                  <p className="px-lg-5 mx-lg-5">
                    Open a business without the hassle of managing inventory,
                    packaging, or shipping.
                  </p>
                  <button className="primary_btn d-inline-flex align-items-center gap-3"
                    onClick={reDirectLinks}
                  >
                    <span> Get Started</span>
                    <img
                      src={require("../assets/images/rightarw.png")}
                      class="img-fluid"
                      alt="arw"
                    />
                  </button>
                </div>
                <img
                  src={require("../assets/images/ban.png")}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  class="img-fluid mt-5 pt-4"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="why_sec" name="why">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 mx-auto text-center mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="h2tag">The Benefits of<span> ShoppyAI</span>
                  {/* Why choose <span> ShoppyAI?</span> */}
                </h2>
                <p>The benefits of AI Dropshipping can be far more than you can imagine if you can make the most of AI power in your dropshipping business. Generally speaking, AI has the potential to improve your dropshipping business by poroviding the following benefits:</p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 col-xl-5 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="box sandal">
                  <div className="row">
                    <div className="col-lg-7">

                      <h5>Save on Dropshipping Costs</h5>
                      <p>By improving the efficiency of repetitive tasks of running a dropshipping store, you can reduce the need for manual labor and potentially lower costs. Besides, winning ads will be easier to create with the help of AI Ad generators, so you can avoid spending more on the wrong ads and campaigns. </p>
                      <button onClick={reDirectLinks}>Get Started</button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w1} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-7 d-flex"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div className="box rose">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>Bring Personalized Shopping Experiences</h5>
                      <p>Some AI tools can analyze customer data such as previous purchases, browsing history, and customer preferences to make personalized product recommendations and offer discounts, improving customer shopping experiences. As the customers are satisfied with your personalized services, they are more likely to make decisions on more purchases.</p>
                      <button onClick={reDirectLinks}>Get Started</button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w2} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-7 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
              >
                <div className="box green mb-lg-0">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>Deliver Engaging Content in Minutes</h5>
                      <p>AI copywriting tools are the most widely used in e-commerce businesses. They have the power to create high-quality content for your website, social media posts, ads, blogs, emails, etc. more efficiently. And some AI image generators can generate compelling image and video designs for your brand.</p>
                      <p>Such tools are trained to generate human-like responses and can provide personalized tones and copywriting according to your preferences and engaging creatives for different social media needs!</p>
                      <button onClick={reDirectLinks}>Get Started</button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w3} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-5 d-flex"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
              >
                <div className="box violet mb-0">
                  <div className="row">
                    <div className="col-lg-7">
                      <h5>Improve Customer Service</h5>
                      <p>AI-fueled chatbots can provide 24/7 customer service, answering common questions about product availability, order status, shipping, return policies, etc., and resolving issues quickly and efficiently. You can customize an AI chatbot for your website to do more such as collecting feedback from customers to help improve your products and services. </p>
                      <button onClick={reDirectLinks}>Get Started</button>
                    </div>
                    <div className="col-lg-5 text-end">
                      <img src={w4} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="supporting"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <h3 className="h2tag">
                  Supporting your growth every step of the way
                </h3>
                <p className="para">
                  Quisque accumsan neque sed sem posuere accumsan ut a elit.
                  Quisque sit amet egestas turpis. Mauris vulputate ornare eros
                  finibus dignissim.
                </p>
                <button onClick={reDirectLinks}>Get Started</button>
              </div>
              <div className="col-lg-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                >
                  <img
                    src={support_img}
                    class="img-fluid mx-auto mt-auto"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="suppliers"
          name="suppliers"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="row">
            <div className="col-lg-6 mx-auto text-center">
              <h2 className="h2tag">Our supported worldwide suppliers</h2>
              <p className="para">
                Maecenas ullamcorper libero sed porttitor aliquam. Suspendisse
                et dapibus purus, dignissim maximus dolor.
              </p>
            </div>
            <div className="boxes">
              <div className="row">
                <div className="col-6 d-flex">
                  <div className="overflow-hidden d-flex align-items-center justify-content-end pe-5 me-5">
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/s1.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s5.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/s1.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s5.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="overflow-hidden d-flex align-items-center justify-content-end ps-5 ms-5">
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/b1.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="box left">
                      <div>
                        <img
                          src={require("../assets/images/b1.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b2.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b3.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/s4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/b4.png")}
                          class="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="round">
                <div>
                  <img src={robo} class="img-fluid" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product" name="integration">
          <div className="container">
            <div className="row" data-aos="fade-up" data-aos-duration="1000">
              <div className="col-lg-6 mx-auto text-center mb-3">
                <h2 className="h2tag">
                  Turn your products into profits at <span> ShoppyAI</span>
                </h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="box">
                  <img src={t1} class="img-fluid icon" alt="img" />
                  <div>
                    <h5>Pick your template</h5>
                    <p className="para">
                      Our templates are designed for easy navigation on any
                      device to ensure a great shopping experience.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="box">
                  <div>
                    <h5>Add your products</h5>
                    <p className="para">
                      Add or import your products, including descriptions,
                      specifications, and images.
                    </p>
                  </div>
                  <img src={t2} class="img-fluid icon" alt="img" />
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="box">
                  <img src={t3} class="img-fluid icon" alt="img" />
                  <div>
                    <h5>Set up your shipping and taxes</h5>
                    <p className="para">
                      Display location-specific tax rates and shipping options
                      in your store.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <div className="box">
                  <div>
                    <h5>Publish your store</h5>
                    <p className="para">
                      Go online with one click and see updates reflected on your
                      website instantly
                    </p>
                  </div>
                  <img src={t4} class="img-fluid icon" alt="img" />
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <div className="box">
                  <img src={t5} class="img-fluid icon" alt="img" />
                  <div>
                    <h5>Integrate with payment gateways</h5>
                    <p className="para">
                      Connect your store with popular payment gateways or let
                      customers pay offline.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="700"
              >
                <div className="box justify-content-between  launch">
                  <div>
                    <h4>Launch Your Own E-Commerce Brand!</h4>
                    <p className="para">Your Path to Profit Starts Here!</p>
                  </div>
                  <div className="w-50 text-end">
                    <button className="primary_btn d-inline-flex align-items-center gap-3">
                      <span onClick={reDirectLinks}> Get Free Trial</span>
                      <img
                        src={require("../assets/images/rightarw.png")}
                        class="img-fluid"
                        alt="arw"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cta">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-8 col-lg-10 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="box">
                  <div className="row">
                    <div className="col-lg-8">
                      <h4>
                        Jumpstart your dropshipping business with{" "}
                        <span>ShoppyAI</span>
                      </h4>
                      <img src={linearw} class="img-fluid icon" alt="img" />
                    </div>
                    <div className="col-lg-4">
                      <button className="d-inline-flex align-items-center gap-3">
                        <span onClick={reDirectLinks}> Get Started</span>
                        <img
                          src={require("../assets/images/rightarw_ylw.png")}
                          class="img-fluid"
                          alt="arw"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="slide_sec">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-3 mb-4 mb-lg-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="h2tag mb-lg-5 mb-4">
                  What do other entrepreneurs say about <span> ShoppyAI</span>
                </h2>
                <div>
                  {/* <a href="#" className="link" onClick={reDirectLinks}>
                    Check out the case studies
                  </a> */}
                  {/* <img src={rightarw} class="img-fluid ms-3" alt="img" /> */}
                </div>
              </div>
              <div
                className="col-lg-8"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="bgbox">
                  <div className="box">
                    <Slider {...settings}>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">I decided to step into the dropshipping business only three months ago.
                            I had no experience and didn't know where to start. I searched for dropshipping software and found Shoppyai.
                            Guess what? After three months, I have already fulfilled 50+ orders!
                            This tool helped me find trending products in health care and organize my online store most effectively.</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p1.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>Landon Le</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">Shoppyai helped me to restart my business.
                            I lost my domestic supplier for women's jewelry and wanted to find new ways for business organization. I was afraid to start dropshipping without goods in my storage; I had never done this before. Nevertheless,
                            I tried so, and expand the choice of lovely ceramic jewelry for my customers and boost my profit!</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p2.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>Graciela Tyler</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">Shoppyai is not my first dropshipping tool.
                            I tried several ones for searching for products to sell, repricing activities, and online store creation.
                            I did not expect everything I could find in one with an affordable product price.
                            Now, I recommend Shoppyai to my colleagues as the best product worth its price.</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p3.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>Edison Price</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-start gap-3">
                          <img src={quote} class="img-fluid" alt="img" />
                          <p className="para">In our digital area, it's nice when something can do all the work for you!
                            Shoppyai is the best automation tool! I manage to have five active online stores where all order fulfillment is automated.
                            I am focusing on promotion campaigns and receiving my profit only.</p>
                        </div>

                        <div className="usr_box">
                          <div className="user">
                            <img
                              src={require("../assets/images/p4.png")}
                              class="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div>
                            <h5>Abel Nelson</h5>
                            {/* <p className="mb-0">CEO, Headphone Zone</p> */}
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="faq" name="faq">
          <div class="container">
            <div className="row">
              <div className="col-lg-7 col-xl-6 mx-auto">
                <div data-aos="fade-up" data-aos-duration="1000">
                  <h2 class="h2tag text-center">Frequently Asked Questions?</h2>
                  <p className="para text-center mx-lg-5 mb-4">
                    Maecenas ullamcorper libero sed porttitor aliquam.
                    Suspendisse et dapibus purus, dignissim maximus dolor.
                  </p>
                </div>
                <div class="accordion" id="accordionExample">

                  {
                    faq && faq.map((ele, i) => (
                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne_${i}`}
                            aria-expanded="true"
                            aria-controls={`collapseOne_${i}`}
                          >
                            {ele.question}?
                          </button>
                        </h2>
                        <div
                          id={`collapseOne_${i}`}
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>
                              {ele.answer}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  }





                  {/* <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How profitable is dropshipping?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Pellentesque vitae volutpat lectus. Nullam faucibus
                          ultricies justo, non volutpat neque volutpat eu.
                          Maecenas ut malesuada lacus, et cursus urna. Morbi
                          venenatis varius lobortis. Donec id eros tellus.
                          Aliquam tincidunt nisl posuere elementum placerat.
                          Fusce id imperdiet felis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How do I start a dropshipping business?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Pellentesque vitae volutpat lectus. Nullam faucibus
                          ultricies justo, non volutpat neque volutpat eu.
                          Maecenas ut malesuada lacus, et cursus urna. Morbi
                          venenatis varius lobortis. Donec id eros tellus.
                          Aliquam tincidunt nisl posuere elementum placerat.
                          Fusce id imperdiet felis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                  >
                    <h2 class="accordion-header" id="headingfour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefour"
                        aria-expanded="false"
                        aria-controls="collapsefour"
                      >
                        What products are best for dropshipping?
                      </button>
                    </h2>
                    <div
                      id="collapsefour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Pellentesque vitae volutpat lectus. Nullam faucibus
                          ultricies justo, non volutpat neque volutpat eu.
                          Maecenas ut malesuada lacus, et cursus urna. Morbi
                          venenatis varius lobortis. Donec id eros tellus.
                          Aliquam tincidunt nisl posuere elementum placerat.
                          Fusce id imperdiet felis.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
