import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios from "axios";
import config from '../config/index'

export default function CheckoutPage() {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [client_secret, setClientSecret] = useState()
  const [err, setErr] = useState(true)

  // useEffect(() => {
  //   CreatePayment();
  // }, [stripe]);
  // const CreatePayment = async () => {
  //   if (!stripe) {
  //     return;
  //   }

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     "payment_intent_client_secret"
  //   );




  //   if (!clientSecret) {
  //     return;
  //   }


  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

  //     switch (paymentIntent.status) {
  //       case "succeeded":

  //         setClientSecret(clientSecret)
  //         setMessage("Payment succeeded!");
  //         break;
  //       case "processing":
  //         setMessage("Your payment is processing.");
  //         break;
  //       case "requires_payment_method":
  //         setMessage("Your payment was not successful, please try again.");
  //         break;
  //       default:
  //         setMessage("Something went wrong.");
  //         break;
  //     }
  //   });
  // };




  // Create the PaymentIntent and obtain clientSecret from your server endpoint

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setIsLoading(false);
      return false;
    }
    setIsLoading(true);


    console.log(stripe, elements, 'stripe,elementsstripe,elementsstripe,elements');

    // localStorage.setItem("clientSecret", elements._commonOptions.clientSecret.id)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${config.front_URL}/dashboard`
      }
    });

    // localStorage.setItem("clientSecret1", elements._commonOptions.clientSecret.id)

    console.log(error, "errorerror");
    if (error.type === "card_error" || error.type === "validation_error") {
      console.log('log1');
      setIsLoading(false);
      setMessage(error.message);
    } else {
      console.log('log2');

      setIsLoading(false);
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };
  const paymentElementOptions = {
    layout: "tabs"
  };

  useEffect(() => {

  }, [isLoading])

  
  return (
    <div>
      {" "}
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />


        <button
          className="primary_btn mb-4 w-100 rounded-2 mt-4"
          disabled={ !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
              Pay now 
          </span>
        </button>


        {/* Show any error or success messages */}
        {message && <div id="payment-message" style={{ color: 'red' }}>{message}</div>}
      </form>
    </div>
  );
}
